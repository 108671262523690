const MIN_DELTA_VALUE = 0;

//TODO: Improve performance by running toFixed filter in controller
//TODO: Investigate why jobStats gets mutated; may have something to do with foamtree
function FoamTreeController($scope, $log, $window, $document, $timeout, jobTracker, jobEvents, userTokens) {
  var CarrotSearchFoamTree = $window.CarrotSearchFoamTree;
  var vm = this;

  let plotDiv = null;
  let gridOptions = null;

  this.ancestryLoading = false;
  this.ancestryResults = null;
  this.ancestryRowData = null;
  this.ancestryState = "Checking ancestry status";

  const focusOnAgGridGroup = (groupName) => {
    var rowNodeToFocus = null;

    gridOptions.api.forEachNode(function (node) {
      if (node.data.sampleId === groupName) {
        rowNodeToFocus = node; // Capture the node to focus
        node.setSelected(true);
      }
    });

    // If a node was found, ensure it's visible at the top of the viewport
    if (rowNodeToFocus !== null) {
      gridOptions.api.ensureNodeVisible(rowNodeToFocus, 'top');
    }
  };

  const hoverListener = (data) => {
    var groupName = data.points[0].data.name;
    focusOnAgGridGroup(groupName);
  };

  const cleanupPCAContainer = () => {
    this.ancestryLoading = false;
    // Remove Plotly event listener
    if (plotDiv) {
      plotDiv.removeListener('plotly_hover', hoverListener);
    }

    // Additional cleanup if needed, like destroying AG-Grid instance
    if (gridOptions && gridOptions.api) {
      gridOptions.api.destroy();
    }
  };

  const fetchAncestryData = () => {
    if (this.ancestryResults) {
      return Promise.resolve(this.ancestryResults);
    }

    this.ancestryLoading = true;

    return fetch(`/api/jobs/${this.job._id}/ancestry`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${userTokens.accessToken}`
      }
    }).then(response => {
      if (!response.ok) {
        if (response.status === 400) {
          return response.json().then(data => {
            this.ancestryState = data.state;
          });
        }

        throw new Error(response.statusText);
      }

      this.ancestryState = "completed";
      return response.json();
    })
      .then(data => {
        if (!data && this.ancestryState === "completed") {
          throw new Error("empty ancestry data");
        }

        this.ancestryResults = data;
        this.ancestryLoading = false;
        return data;
      })
      .catch(error => {
        this.ancestryLoading = false;
        this.ancestryState = error;
        throw error;
      });
  };


  this.createPcsBiplot = (pcs) => {
    if (!pcs) {
      alert("No PCS found!");
      return;
    }

    // Prepare the data for Plotly
    const data = Object.entries(pcs).map(([label, values]) => {
      return {
        x: [values[0]],
        y: [values[1]],
        type: 'scatter',
        mode: 'markers',
        name: label,
        text: ['Sample: ' + label],
        marker: { size: 12 }
      };
    });

    this.dataSpread = calculateSpread(data);

    // Layout configuration
    const layout = {
      title: 'Principal Component Biplot',
      xaxis: { title: 'PC1' },
      yaxis: { title: 'PC2' },
      hovermode: 'closest'
    };

    // Create the Plotly plot
    plotDiv = document.getElementById('biplot');
    Plotly.newPlot(plotDiv, data, layout);

    plotDiv.on('plotly_hover', hoverListener);
  };

  vm.isObject = angular.isObject;

  var _spinner = null;

  vm.visibleGroup = null;
  vm.menuHover = false;
  vm.promiseCloseWhenOff = false;

  vm.showPopGen = true;
  vm.showAncestry = false;

  vm.ancestryState = "checking";

  $scope.$on(`${jobEvents.eventPrefix}${jobEvents.events.ancestry.started}`, (event, job) => {
    if (job._id === vm.job._id) {
      vm.job = job;
      vm.ancestryState = "started";
    }
  });

  $scope.$on(`${jobEvents.eventPrefix}${jobEvents.events.ancestry.failed}`, (event, job) => {
    if (job._id === vm.job._id) {
      vm.job = job;
      vm.ancestryState = "failed";
    }
  });

  $scope.$on(`${jobEvents.eventPrefix}${jobEvents.events.ancestry.completed}`, (event, job) => {
    if (job._id === vm.job._id) {
      vm.job = job;
      vm.ancestryState = "completed";
      vm.enableGlobalAncestry();
    }
  });

  vm.enablePopGen = () => {
    vm.showAncestry = false;
    vm.showPopGen = true;
    $scope.$evalAsync(() => {
      this.createFoamtree();
    });
  };

  vm.enableGlobalAncestry = () => {
    vm.showAncestry = true;
    vm.showPopGen = false;

    $scope.$evalAsync(() => {
      this.clearFoamTree();

      this.calculateAncestry();
    });

  };

  this.calculateAncestry = () => {
    cleanupPCAContainer();
    this.ancestryLoading = true;

    fetchAncestryData()
      .then(ancestryData => {
        if (!ancestryData) {
          return;
        }

        this.ancestryPcs = ancestryData.pcs;
        this.ancestryData = ancestryData;

        const rowData = this.createAncestryTable(ancestryData);
        this.ancestryRowData = rowData;
        this.createPcsBiplot(ancestryData.pcs);
      })
      .finally(() => { this.ancestryLoading = false; });
  };


  // function highlightPoint(plotDiv, traceIndex, pointIndex) {
  //   const update = {
  //     'marker.size': [[20]], // Increase the marker size; use a nested array to specify per-point sizes
  //     'marker.color': [['#428bca']] // Change the marker color; similarly, use a nested array
  //   };

  //   // Apply the style update to the specified point
  //   Plotly.restyle(plotDiv, update, [traceIndex]);
  // }


  this.focusPlotlyOnSample = (sampleId) => {
    const coordinates = getCoordinatesForSampleId(sampleId);

    const delta = getDynamicDelta(this.dataSpread);

    if (!coordinates) {
      $log.warn("Coordinates for sampleId not found:", sampleId);
      return;
    }

    // Calculate a range around the point to focus on
    const xRange = [coordinates.x - delta.xDelta, coordinates.x + delta.xDelta];
    const yRange = [coordinates.y - delta.yDelta, coordinates.y + delta.yDelta];

    // Update the Plotly plot to zoom in on the selected point
    Plotly.relayout('biplot', {
      'xaxis.range': xRange,
      'yaxis.range': yRange
    });

    // Highlight the selected point
    // if (coordinates.traceIndex !== null) {
    //   const plotDiv = document.getElementById('biplot');

    //   highlightPoint(plotDiv, coordinates.traceIndex, coordinates.pointIndex);
    // }
  };

  this.createAncestryTable = (ancestryResults) => {
    const rowData = [];

    $log.info("ancestryResults", ancestryResults);

    gridOptions = {
      columnDefs: [{ headerName: "Sample ID", field: "sampleId", minWidth: 160 },
      { headerName: "# Model Variants Included", field: "nSnps", minWidth: 160 },
      {
        headerName: "Most Likely Super Population",
        valueGetter: (params) => {
          const populations = Object.keys(params.data.superpops); // or however you can obtain this array

          const sortedPopulations = populations.sort((a, b) => {
            const boundsA = params.data.superpops[a];
            const meanA = (boundsA.lowerBound + boundsA.upperBound) / 2;
            const boundsB = params.data.superpops[b];
            const meanB = (boundsB.lowerBound + boundsB.upperBound) / 2;
            return meanB - meanA;
          });

          const top = sortedPopulations[0];
          const mean = (params.data.superpops[top].lowerBound + params.data.superpops[top].upperBound) / 2;
          return top ? `${top}: ${mean.toFixed(2)}` : 'N/A';
        },
        minWidth: 230,
      },
      {
        headerName: "Most Likely Population",
        field: "topHit",
        valueGetter: (params) => `${params.data.topHit.populations.join(', ')}: ${params.data.topHit.probability.toFixed(2)}`,
        comparator: (valueA, valueB, nodeA, nodeB) => {
          const pop1 = nodeA.data.topHit.populations.join(',');
          const pop2 = nodeB.data.topHit.populations.join(',');

          // First compare the populations
          const popComparison = pop1.localeCompare(pop2);
          if (popComparison !== 0) {
            return popComparison;
          }

          // If populations are the same, compare the probabilities
          return nodeA.data.topHit.probability - nodeB.data.topHit.probability;
        },
        minWidth: 190
      }]
    };

    Object.entries(ancestryResults.results[0].superpops).forEach(([superpop, superBounds]) => {
      gridOptions.columnDefs.push({
        headerName: superpop,
        field: superpop,
        valueGetter: (params) => {
          const bounds = params.data.superpops[superpop];
          const mean = (bounds.lowerBound + bounds.upperBound) / 2;

          // to get +/- take the average distance from the mean
          const distance = (mean - (bounds.lowerBound) + (bounds.upperBound - mean)) / 2;

          return `${mean.toFixed(2)} +/- ${distance.toFixed(2)}`;
        },
        comparator: (valueA, valueB, nodeA, nodeB) => {
          const boundsA = nodeA.data.superpops[superpop];
          const meanA = (boundsA.lowerBound + boundsA.upperBound) / 2;
          const boundsB = nodeB.data.superpops[superpop];
          const meanB = (boundsB.lowerBound + boundsB.upperBound) / 2;
          return meanA - meanB;
        },

        minWidth: 150
      });

    });


    Object.entries(ancestryResults.results[0].populations).forEach(([population, bounds]) => {
      gridOptions.columnDefs.push({
        headerName: population,
        field: population,
        valueGetter: (params) => {
          const bounds = params.data.populations[population];
          const mean = (bounds.lowerBound + bounds.upperBound) / 2;
          return `${mean.toFixed(2)} +/- ${(mean - bounds.lowerBound).toFixed(2)}`;
        },
        comparator: (valueA, valueB, nodeA, nodeB) => {
          const boundsA = nodeA.data.populations[population];
          const meanA = (boundsA.lowerBound + boundsA.upperBound) / 2;
          const boundsB = nodeB.data.populations[population];
          const meanB = (boundsB.lowerBound + boundsB.upperBound) / 2;
          return meanA - meanB;
        },

        minWidth: 150
      });
    });

    gridOptions['defaultColDef'] = {
      flex: 1,
      minWidth: 100,
      sortable: true,
      resizable: true,
    };

    gridOptions['rowClassRules'] = {
      'highlight-row': function (params) {
        return params.node.id === 'rowIdToHighlight'; // condition to highlight the row
      },
    };


    gridOptions['onRowClicked'] = (event) => {
      const sampleId = event.data.sampleId;
      this.focusPlotlyOnSample(sampleId);
    };

    gridOptions['alwaysShowHorizontalScroll'] = true;
    gridOptions['alwaysShowVerticalScroll'] = true;

    const eGridDiv = document.getElementById("myGrid");

    new window.agGrid.Grid(eGridDiv, gridOptions);
    $scope.$evalAsync(() => {
      gridOptions.api.setRowData(ancestryResults.results);
    });


    return rowData;
  };

  function debounce(func, wait) {
    let timeout;
    return function () {
      const context = this, args = arguments;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), wait);
    };
  }

  // Adjusts Plotly plot size and ag-Grid size on window resize
  function adjustLayoutOnResize() {
    // Resize ag-Grid
    if (gridOptions && gridOptions.api) {
      gridOptions.api.sizeColumnsToFit();
    }

    // Resize Plotly Plot
    var plotContainer = document.getElementById('biplot');
    if (plotContainer) {
      // Adjust these values based on your layout requirements
      var newWidth = plotContainer.offsetWidth;
      var newHeight = plotContainer.offsetHeight; // Or set a specific height

      Plotly.relayout(plotContainer, {
        width: newWidth,
        height: newHeight
      });
    }
  }

  function stripPlusMinusValue(cellValue) {
    // This regex matches the " +/- NN.NN" part (or any variation thereof) and replaces it with an empty string
    if (typeof cellValue === 'string') { // Ensure we only try to replace on string values
      return cellValue.replace(/ \+\/\- \d+(\.\d+)?$/, '');
    }
    return cellValue;
  }

  function getCoordinatesForSampleId(sampleId) {
    const plotDiv = document.getElementById('biplot');
    const plotData = plotDiv.data;

    // Search for the sampleId in the plot data
    for (let i = 0; i < plotData.length; i++) {
      if (plotData[i].name === sampleId) {
        return { x: plotData[i].x[0], y: plotData[i].y[0], traceIndex: i, pointIndex: 0 }; // Assuming each trace has one point for simplicity
      }
    }
    return null;
  }

  this.exportDataAsCsv = () => {
    gridOptions.api.exportDataAsCsv({
      fileName: `${this.job.name}_ancestry_scores.csv`, processCellCallback: (params) => {
        // Apply the stripping logic to every cell's value
        return stripPlusMinusValue(params.value);
      }
    });
  };

  this.exportDataAsExcel = () => {
    // Note: Excel export is a feature of ag-Grid Enterprise
    if (gridOptions.api.exportDataAsExcel) {
      gridOptions.api.exportDataAsExcel({
        fileName: `${this.job.name}_ancestry_scores.xlsx`, processCellCallback: (params) => {
          // Apply the stripping logic to every cell's value
          return stripPlusMinusValue(params.value);
        }
      });
    }
  };

  function calculateSpread(data) {
    const xValues = data.map(point => point.x);
    const yValues = data.map(point => point.y);
    const xMin = Math.min(...xValues);
    const xMax = Math.max(...xValues);
    const yMin = Math.min(...yValues);
    const yMax = Math.max(...yValues);

    return {
      xRange: xMax - xMin,
      yRange: yMax - yMin,
    };
  }

  function getDynamicDelta(spread) {
    // Set delta as a percentage of the range
    const delta = {
      xDelta: spread.xRange * 0.45, // 45% of the x range
      yDelta: spread.yRange * 0.45, // 45% of the y range
    };

    // Ensure delta has a minimum value to prevent it from being too small
    delta.xDelta = Math.max(delta.xDelta, MIN_DELTA_VALUE);
    delta.yDelta = Math.max(delta.yDelta, MIN_DELTA_VALUE);

    return delta;
  }

  this.downloadAncestryJson = () => {
    if (!this.ancestryData) {
      alert('No ancestry data to download');
      return;
    }
    // Convert JSON Data to string
    const jsonString = JSON.stringify(this.ancestryData, null, 2); // Beautify the JSON output

    // Create a Blob from the JSON String
    const blob = new Blob([jsonString], { type: "application/json" });

    // Create a URL for the blob
    const url = URL.createObjectURL(blob);

    // Create a temporary anchor tag to trigger download
    const a = document.createElement("a");
    a.href = url;
    a.download = `${this.job.name}_ancestry_data.json`; // Filename for the downloaded file

    // Append the anchor to the document, trigger click, and then remove it
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    // Revoke the blob URL
    URL.revokeObjectURL(url);
  };
  // Debounce the resize function to improve performance
  var debouncedAdjustLayoutOnResize = debounce(adjustLayoutOnResize, 100);

  // Add the debounced resize event listener
  window.addEventListener('resize', debouncedAdjustLayoutOnResize);

  vm.summary = null;
  vm.foamTree = null;
  vm.carrotArray = null;

  vm.clearFoamTree = clearFoamTree;
  vm.hasFoamtreeData = hasFoamtreeData;

  vm.visibleGroupRatios = {};

  vm.closeGroupIfNotHover = closeGroupIfNotHover;
  vm.totalGroup = [];
  vm.numberOfSamples = 0;

  function getHeight() {
    return Math.max($document[0].documentElement.clientHeight, $window.innerHeight || 0) * 6 / 8;
  }

  this.loading = false;

  this.onResize = () => {
    let timeout;
    return function resize() {
      $window.clearTimeout(timeout);

      element.css('max-height', getHeight());

      $window.setTimeout(this.foamTree.resize, 100);
    };
  };

  this.onOrientationChange = () => {
    if (this.foamTree) {
      element.css('max-height', getHeight());

      this.foamTree.resize();
    }
  };

  this.onKeyup = (e) => {
    if (e.keyCode == 27) { // escape key maps to keycode `27`
      this.visibleGroup = null;
    }
  };

  this.createFoamtree = (sampleWeights) => {
    this.loading = true;
    clearFoamTree();

    createCarrotObject(this.jobStats, sampleWeights, (carrotArray) => {
      $timeout(() => {
        if (carrotArray && carrotArray.length) {
          createFoamTree(this.jobStats, 'visualization');

          this.foamTree.set({ dataObject: { groups: carrotArray } });

          $window.addEventListener('orientationchange', this.onOrientationChange);
          $window.addEventListener('resize', this.onResize);
          $window.addEventListener('keyup', this.onKeyup);
        }

        this.loading = false;
      }, 250, true);
    });
  };

  this.updateWeights = (sampleWeight) => {
    this.carrotArray.forEach((sampleObj) => {
      sampleObj.weight = this.jobStats.results.samples[sampleObj.label][sampleWeight];
    });

    this.foamTree.update();
  };

  // apparently dom manipulation best here, after angular finishes
  // binding component to dom
  this.$postLink = () => {
    if (vm.showPopGen) {
      this.createFoamtree();
    } else if (vm.showAncestry) {
      this.calculateAncestry();
    }
  };

  this.$onDestroy = () => {
    $window.removeEventListener('keyup', this.onKeyup);
    $window.removeEventListener('resize', this.onResize);
    $window.removeEventListener('orientationChange', this.onOrientationChange);


  };

  this.updateJob = (prop, val) => {
    this.updating = true;

    const updateProp = {};
    updateProp[prop] = val;

    jobTracker.patch(this.job, updateProp).then((updatedJob) => {
      this.job = updatedJob;
      this.updateSuccess = true;
    }).catch((err) => {
      this.updateErr = err;
      $log.error(err);
    }).finally(() => {
      this.updating = false;
    });
  };


  this.addSampleNote = (sample, val, removeIdx, ev) => {
    if (!(this.job && this.jobStats)) {
      $log.error('couldn\'t find job results', this.job);
      return;
    }

    if ((removeIdx === null || removeIdx === undefined) && (val === null || val === undefined)) {
      $log.error('addSampleNote must be called with val or removeIdx');
      return;
    }

    const sampleNotes = _.assign({}, this.jobStats.sampleNotes || {});

    let existing = [];

    if (sampleNotes[sample]) {
      existing = [].concat(sampleNotes[sample]);
    }

    if (removeIdx >= 0) {
      if (!existing.length) {
        return;
      }

      existing.splice(removeIdx, 1);
    } else {
      existing.push(val);
    }

    if (!existing.length && sample in sampleNotes) {
      delete sampleNotes[sample];
    } else {
      sampleNotes[sample] = existing;
    }

    return this.updateJob('results.sampleNotes', sampleNotes);
  };

  /* Public*/
  function createCarrotObject(jobStats, sampleWeights, cb) {
    if (!jobStats || !Object.keys(jobStats).length) {
      $log.warn('no results found', angular.copy(job));
      return cb(null);
    }

    vm.carrotArray = _buildCarrotGraph(jobStats, sampleWeights);

    cb(vm.carrotArray);
  }

  var timeout = null;
  var redrawn = false;
  function createFoamTree(jobStats, elementID) {
    if (vm.foamTree) {
      //Tears down the tree, in case clearFoamTree not called in time
      //Prevents errors due to already-attached tree
      //Prevents memory leaks
      // but this has errors...
      // vm.foamTree.dispose();
    }

    vm.foamTree = new CarrotSearchFoamTree({
      id: elementID, // what elem id binds to, set in directive
      dataObject: {
        groups: vm.carrotArray,
      },
      groupColorDecorator: function (opts, params, vars) {
        vars.groupColor = params.group.color;
        vars.labelColor = 'auto';
      },
      pixelRatio: window.devicePixelRatio || 1,
      layout: 'ordered',
      // relaxationInitializer: 'squarified',
      // Remove restriction on the minimum group diameter, so that
      // we can render as many diagram levels as possible.
      groupMinDiameter: 1,

      // Lower the minimum label font size a bit to show more labels.
      groupLabelMinFontSize: 3,

      maxGroupLevelsDrawn: 2,

      // Disable rounded corners, deeply-nested groups
      // will look much better and render faster.
      groupBorderRadius: 0,

      // Lower the parent group opacity, so that lower-level groups show through.
      parentFillOpacity: 0.5,

      // Lower the border radius a bit to fit more groups.
      groupBorderWidth: 0.5,
      groupInsetWidth: 2,
      groupSelectionOutlineWidth: 0.1,
      // maxGroupLevelsDrawn: 1,
      groupFillType: 'plain',
      // wireframeLabelDrawing: 'never',
      stacking: 'hierarchical',
      // relaxationVisible: true,
      // relaxationVisible: true,

      // Less processing time
      relaxationQualityThreshold: 10,
      // relaxationMaxDuration: 15000,
      // groupBorderWidth: 0.2,
      groupStrokeWidth: 0.1,
      // groupBorderRadius: 0,
      groupBorderWidthScaling: 0.5,
      rolloutDuration: 0,
      pullbackDuration: 0,
      // relaxationVisible: 1,
      // groupStrokeType: 'none',

      onRolloutComplete: function () {
        this.resize();
      },
      onGroupDoubleClick: function (obj) {
        if (!obj.group) {
          return;
        }

        var self = this;
        $scope.$evalAsync(function () {
          if (!obj.group.groups && !obj.group.secondary) {
            load(obj.group, self);
          }
        });
      },
      onGroupClick: function (obj) {
        if (!obj.group) {
          return;
        }

        var self = this;
        $scope.$evalAsync(function () {
          vm.promiseCloseWhenOff = false;
          vm.visibleGroup = {
            ratios: jobStats.results.samples[obj.group.label],
            label: obj.group.label,
            weight: obj.group.weight,
            qc: obj.group.qc,
          };
        });
      },
    });

    function load(group, foamtree) {
      if (!group.groups && !group.loading) {
        group.groups = [];

        if (_spinner == null) {
          _spinner = makeSpinner(foamtree);
        }

        window.requestAnimationFrame(function () {
          _spinner.start(group);
        });

        vm.outputKeys.forEach(function (innerKey) {
          group.groups.push({
            label: innerKey,
            color: group.color,
            weight: Number(jobStats.results.samples[group.label][innerKey]),
            secondary: true,
          });
        });

        window.requestAnimationFrame(function () {
          // We need to open the group for FoamTree to update its model
          foamtree.open({ groups: group, open: true }).then(function () {
            // spinner.stop(group);
            group.loading = false;
            _spinner.stop(group);
          });
        });
      }
    }
  }

  function clearFoamTree() {
    if (vm.foamTree) {
      _spinner = null;
      vm.carrotArray = null;
      vm.visibleGroup = null;
      vm.foamTree.set({ dataObject: { groups: [] } });
    }


    const existingFoamtreeElement = document.getElementById('visualization');
    if (existingFoamtreeElement) {
      existingFoamtreeElement.removeAttribute('data-foamtree');
    }

  }

  function hasFoamtreeData() {
    return !!Object.keys(vm.foamTree).length;
  }

  function getStatKeys() {

  }

  var _maxFail = 0;
  var count = 0;
  var numberGroupCalled = 0;

  function _buildCarrotGraph(resultsObj, sampleWeights = 'transitions/transversions') {
    if (!resultsObj || !Object.keys(resultsObj).length) {
      return null;
    }
    // numberGroupCalled++;
    var allObjects = [];
    var isTotal;

    vm.outputKeys = [].concat(resultsObj.results.order);
    vm.badSamples = [];

    // New style
    if (resultsObj.results.qc) {
      let maxFailures = 0;

      // Our mean/median/sd are stored in objects, every other summary info
      // is a scalar
      Object.keys(resultsObj.stats).forEach((key) => {
        if (typeof resultsObj.stats[key] === 'object') {
          maxFailures++;
        }
      });

      for (const sample in resultsObj.results.samples) {
        if (sample === 'total') {
          continue;
        }

        let weight = Number(resultsObj.results.samples[sample][sampleWeights]);

        let color = '#ffffff';

        const failures = resultsObj.results.qc[sample];

        if (failures) {
          const numFailures = failures && failures.length;

          color = lerpColor(color, '#8a0202', numFailures / maxFailures);
        }

        const markedGood = resultsObj.results.markedGood && resultsObj.results.markedGood[sample];
        const markedBad = resultsObj.results.markedBad && resultsObj.results.markedBad[sample];

        const sampleObj = { weight, color, label: sample, qc: failures };

        if (!markedGood && (markedBad || failures)) {
          vm.badSamples[sample] = true;
        }

        vm.visibleGroupRatios[sample] = {};
        allObjects.push(sampleObj);
      }

      return allObjects;
    }

    // Old... Not configurable
    const _sdKey = 'transitions:transversions ratio standard deviation';
    const _meanKey = 'transitions:transversions ratio mean';
    const _weightKey = 'total transitions:transversions ratio';
    const trTvSd = resultsObj.stats[_sdKey];
    const trTvMean = resultsObj.stats[_meanKey];

    let deviantUp;
    let deviantDown;
    if (trTvSd && trTvMean) {
      deviantUp = trTvSd * 3 + trTvMean;
      deviantDown = trTvMean - trTvSd * 3;
    }

    if (sampleWeights == 'transitions/transversions') {
      sampleWeights = _weightKey;
    }

    for (const sample in resultsObj.results.samples) {

      if (sample !== 'total') {
        const weight = Number(resultsObj.results.samples[sample][sampleWeights]);
        const tsTv = Number(resultsObj.results.samples[sample][_weightKey]);
        const tObj = { weight: weight, label: sample };

        if (deviantUp !== undefined && tsTv > deviantUp) {
          tObj.color = "#a54243";
          tObj.qc = ['Tr:Tv mean >= 3SD experiment mean'];
        } else if (deviantDown !== undefined && tsTv < deviantDown) {
          tObj.color = "#428bca";
          tObj.qc = ['Tr:Tv mean <= 3SD experiment mean'];
        } else {
          tObj.color = "#fff";
        }

        const markedGood = resultsObj.results.markedGood && resultsObj.results.markedGood[sample];
        const markedBad = resultsObj.results.markedBad && resultsObj.results.markedBad[sample];

        if (!markedGood && (markedBad || tObj.failure)) {
          vm.badSamples[sample] = true;
        }

        vm.visibleGroupRatios[sample] = {};

        allObjects.push(tObj);
      }
    }
    return allObjects;
  }

  function closeGroupIfNotHover() {
    if (vm.promiseCloseWhenOff) {
      vm.visibleGroup = null;
    }
    vm.menuHover = null;
    vm.promiseCloseWhenOff = false;
  }

  // expects object to have property annotationSummary
  var _tryJson = function (maybeJson) {
    if (maybeJson && typeof maybeJson === 'object') {
      return maybeJson;
    }
    try {
      return JSON.parse(maybeJson);
    } catch (err) {
      $log.warn(err, maybeJson);
      return null;
    }
  };

  // Combine with filter in directive
  function toTitleCase(input) {
    input = input || '';
    return input.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1); });
  }

  //
  // A simple utility for starting and stopping spinner animations
  // inside groups to show that some content is loading.
  //
  function makeSpinner(foamtree) {
    // Set up a groupContentDecorator that draws the loading spinner
    foamtree.set("wireframeContentDecorationDrawing", "always");
    foamtree.set("groupContentDecoratorTriggering", "onSurfaceDirty");
    foamtree.set("groupContentDecorator", function (opts, props, vars) {
      var group = props.group;
      if (!group.loading) {
        return;
      }

      // Draw the spinner animation

      // The center of the polygon
      var cx = props.polygonCenterX;
      var cy = props.polygonCenterY;

      // Drawing context
      var ctx = props.context;

      // We'll advance the animation based on the current time
      var now = Date.now();

      // Some simple fade-in of the spinner
      var baseAlpha = 0.3;
      if (now - group.loadingStartTime < 200) {
        baseAlpha *= Math.pow((now - group.loadingStartTime) / 200, 2);
      }

      // If polygon changed, recompute the radius of the spinner
      if (props.shapeDirty || group.spinnerRadius === undefined) {
        // If group's polygon changed, recompute the radius of the inscribed polygon.
        group.spinnerRadius = CarrotSearchFoamTree.geometry.circleInPolygon(props.polygon, cx, cy) * 0.4;
      }

      // Draw the spinner
      var angle = 2 * Math.PI * (now % 1000) / 1000;
      ctx.globalAlpha = baseAlpha;
      ctx.beginPath();
      ctx.arc(cx, cy, group.spinnerRadius, angle, angle + Math.PI / 5, true);
      ctx.strokeStyle = "#428bca";
      ctx.lineWidth = group.spinnerRadius * 0.3;
      ctx.stroke();

      // Schedule the group for redrawing
      foamtree.redraw(true, group);
    });

    return {
      start: function (group) {

        group.loading = true;
        group.loadingStartTime = Date.now();

        // Initiate the spinner animation
        foamtree.redraw(true, group);
      },

      stop: function (group) {
        group.loading = false;
      }
    };
  }
}


/**
 * A linear interpolator for hexadecimal colors
 * @param {String} a
 * @param {String} b
 * @param {Number} amount
 * @example
 * // returns #7F7F7F
 * lerpColor('#000000', '#ffffff', 0.5)
 * @returns {String}
 */
function lerpColor(a, b, amount) {

  var ah = parseInt(a.replace(/#/g, ''), 16),
    ar = ah >> 16, ag = ah >> 8 & 0xff, ab = ah & 0xff,
    bh = parseInt(b.replace(/#/g, ''), 16),
    br = bh >> 16, bg = bh >> 8 & 0xff, bb = bh & 0xff,
    rr = ar + amount * (br - ar),
    rg = ag + amount * (bg - ag),
    rb = ab + amount * (bb - ab);

  return '#' + ((1 << 24) + (rr << 16) + (rg << 8) + rb | 0).toString(16).slice(1);
}

angular.module('sq.jobs.results.foamTree.component', ['sq.jobs.tracker.service', 'sq.jobs.events.service', 'sq.user.auth.tokens'])
  .component('myComponent', {
    template: '<button ng-click="$ctrl.showAlert()">Click Me</button>',
    controller: function () {
      this.showAlert = function () {
        alert('Button clicked!');
      };
    }
  })
  .directive('myNoRowsTemplate', function () {
    return {
      restrict: 'E',
      template: '<div class="ag-overlay-no-rows-center">No data available.</div>'
    };
  })
  .component('sqFoamTree', {
    bindings: {
      job: '<jobResource',
      jobStats: '<'
    },
    templateUrl: 'jobs/results/foamTree/templates/sqFoamTree.tpl.html',
    controller: FoamTreeController,
  });